<template>
  <div>
    <div class="mb-2 flex justify-end">
      <div
          class="d-flex smallGap text-secondaryColor align-items-center"
          role="button"
          @click="$router.go(-1)"
      >
        <mdicon :width="25" :height="25" name="arrow-left-thick"/>
        <p class="mb-0" v-text="$t(locale.back)"/>
      </div>
    </div>
    <b-card>
      <div class="d-flex justify-content-between align-items-center">
        <b-card-title class="mb-0">
          {{ $t(locale.createSegment) }}
        </b-card-title>
        <!-- <LittleTabs :tabs="tabs" :switchTabs="switchTabs" /> -->
      </div>
    </b-card>
    <!-- <ValidationObserver ref="form">
        <KeepAlive>
            <transition name="zoom-fade" mode="out-in">
                <component :is="currentComponent" />
            </transition>
        </KeepAlive>
    </ValidationObserver> -->

    <ValidationObserver ref="segmentForm">
      <div v-show="tabSwitch === 1">
        <b-row>
          <b-col cols="12" lg="12">
            <SecondaryCard :title="$t(locale.details)">
              <b-container fluid>
                <b-row>
                  <b-col cols="12" lg="8">
                    <b-row>
                      <b-col cols="12" lg="4">
                        <b-form-group
                            :label="$t(locale.name)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleSegment.name"
                              :placeholder="$t(locale.name)"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" lg="4">
                        <validation-provider
                            #default="{errors}"
                            :rules="'required'"
                            :name="$t(locale.description)"
                        >
                          <b-form-group
                              :label="$t(locale.description)"
                              label-for="input-1"
                          >
                            <b-form-input
                                size="sm"
                                v-model="singleSegment.description"
                                :placeholder="$t(locale.description)"
                            />
                            <small
                                class="text-danger"
                                v-text="errors[0]"
                            />
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <b-col cols="12" lg="4">
                        <b-form-group
                            :label="$t(locale.invoiceDiscount)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleSegment.invoice_discount"
                              type="number"
                              min="0"
                              :placeholder="$t(locale.invoiceDiscount)"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" lg="4">
                        <b-form-group
                            :label="$t(locale.shippingCharge)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleSegment.shipping_charge"
                              :placeholder="$t(locale.shippingCharge)"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" lg="4">
                        <b-form-group
                            :label="$t(locale.recurring)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleSegment.recurring_billing"
                              type="number"
                              min="0"
                              :placeholder="$t(locale.recurring)"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="4">
                        <b-form-group
                            :label="$t(locale.invoiceCharge)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              v-model="singleSegment.invoice_charge"
                              :placeholder="$t(locale.invoiceCharge)"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="4">
                        <b-form-group
                            :label="$t(locale.invoiceFreight)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              type="number"
                              min="0"
                              v-model="singleSegment.invoice_freight"
                              :placeholder="$t(locale.invoiceFreight)"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="4">
                        <b-form-group
                            :label="$t(locale.invoiceAdminFee)"
                            label-for="input-1"
                        >
                          <b-form-input
                              size="sm"
                              type="number"
                              min="0"
                              v-model="singleSegment.invoice_administration_fee"
                              :placeholder="$t(locale.invoiceAdminFee)"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>

                  <b-col cols="12" lg="4">
                    <b-row>
                      <b-col cols="12" lg="6">
                        <b-form-group
                            :label="$t(locale.active)"
                            label-for="input-1"
                        >
                          <LittleTabs
                              :tabs="[
                                                        {
                                                            label: 'CustomersCardEdit.yes',
                                                            active: true,
                                                            value: true,
                                                        },
                                                        {
                                                            label: 'CustomersCardEdit.no',
                                                            active: false,
                                                            value: false,
                                                        },
                                                    ]"
                              v-model="singleSegment.active"
                              :defaultValue="true"
                              buttonSize="sm"
                              containerStyle="justify-content-start"
                              btnClass="tabWrapperSmall2"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="6">
                        <b-form-group
                            :label="$t(locale.priceIncVat)"
                            label-for="input-1"
                        >
                          <LittleTabs
                              :tabs="[
                                                        {
                                                            label: 'CustomersCardEdit.yes',
                                                            active: true,
                                                            value: true,
                                                        },
                                                        {
                                                            label: 'CustomersCardEdit.no',
                                                            active: false,
                                                            value: false,
                                                        },
                                                    ]"
                              v-model="singleSegment.price_include_vat"
                              :defaultValue="true"
                              buttonSize="sm"
                              containerStyle="justify-content-start"
                              btnClass="tabWrapperSmall2"
                          />
                        </b-form-group>
                      </b-col>
                      <b-col cols="12" lg="6">
                        <b-form-group
                            :label="$t(locale.interestBilling)"
                            label-for="input-1"
                        >
                          <LittleTabs
                              :tabs="[
                                                        {
                                                            label: 'CustomersCardEdit.yes',
                                                            active: true,
                                                            value: true,
                                                        },
                                                        {
                                                            label: 'CustomersCardEdit.no',
                                                            active: false,
                                                            value: false,
                                                        },
                                                    ]"
                              v-model="singleSegment.interest_billing"
                              :defaultValue="true"
                              buttonSize="sm"
                              containerStyle="justify-content-start"
                              btnClass="tabWrapperSmall2"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" lg="6">
                        <b-form-group
                            :label="$t(locale.typeOfVat)"
                            label-for="input-1"
                        >
                          <select v-model="singleSegment.type_of_vat"
                                  class="bg-white focus:outline-none c-p-2 rounded-md flex justify-center custom-border border-gray-300 focus:border-blue-200">
                            <option value="SEVAT">SE</option>
                            <option value="SEREVERSEDVAT">SE reverse Vat</option>
                            <option value="EUREVERSEDVAT">EU reverse Vat</option>
                            <option value="EUVAT">Subject to EU VAT</option>
                            <option value="EXPORT">Export</option>
                          </select>
                          <!-- <v-select
                                  :placeholder="$t(locale.typeOfVat)"
                                  :options="[
                                      {
                                          label : 'SE',
                                          value : 'SEVAT'
                                      },
                                      {
                                          label : 'SE reverse Vat',
                                          value : 'SEREVERSEDVAT'
                                      },
                                      {
                                          label : 'EU reverse Vat',
                                          value : 'EUREVERSEDVAT'
                                      },
                                      {
                                          label : 'Subject to EU VAT',
                                          value : 'EUVAT'
                                      },
                                      {
                                          label : 'Export',
                                          value : 'EXPORT'
                                      },
                                  ]"
                                  v-model="singleSegment.type_of_vat"
                                  @option:selected="computeSelect($event, 'type_of_vat')"
                                  class="resource-selector d-inline-block w-full"
                              /> -->
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-container>
            </SecondaryCard>
          </b-col>

          <b-col cols="12" lg="8">
            <SecondaryCard :title="$t(locale.invoiceDistribution)" class="mt-2">
              <b-container fluid>
                <b-row>
                  <b-col cols="12" lg="4">
                    <div class="flex flex-col">
                      <!-- <p
                          class="h4 mb-2"
                          v-text="$t(locale.invoice)"
                      /> -->
                      <div class="w-full">
                        <b-form-group
                            :label="$t(locale.invoice)"
                            label-for="input-1"
                        >
                          <LittleTabs
                              :tabs="[
                                                            {
                                                                label: 'InvoiceCardCustomerEdit.print',
                                                                active: true,
                                                                value: 'print',
                                                            },
                                                            {
                                                                label: 'CustomersCardEdit.firstForm.email',
                                                                active: false,
                                                                value: 'email',
                                                            },
                                                            {
                                                                label: 'InvoiceCardCustomerEdit.einvoice',
                                                                active: false,
                                                                value: 'einvoice',
                                                            },
                                                        ]"
                              buttonSize="sm"
                              containerStyle="justify-content-start"
                              btnClass="tabWrapperSmall2"
                              v-model="singleSegment.invoice"
                              :defaultValue="true"
                          />
                        </b-form-group>
                      </div>
                      <div class="w-full">
                        <b-form-group
                            :label="$t(locale.invoiceText)"
                            label-for="input-1"
                        >
                          <b-form-textarea
                              v-model="singleSegment.invoice_text"
                              :placeholder="$t(locale.invoiceText)"
                          />
                        </b-form-group>
                      </div>
                    </div>
                  </b-col>

                  <b-col cols="12" lg="4">
                    <div class="flex flex-col">
                      <!-- <p
                          class="h4 mb-2"
                          v-text="$t(locale.quote)"
                      /> -->
                      <div class="w-full">
                        <b-form-group
                            :label="$t(locale.quote)"
                            label-for="input-1"
                        >
                          <LittleTabs
                              :tabs="[
                                                            {
                                                                label: 'InvoiceCardCustomerEdit.print',
                                                                active: true,
                                                                value: 'print',
                                                            },
                                                            {
                                                                label: 'CustomersCardEdit.firstForm.email',
                                                                active: false,
                                                                value: 'email',
                                                            },
                                                        ]"
                              buttonSize="sm"
                              containerStyle="justify-content-start"
                              btnClass="tabWrapperSmall2"
                              v-model="singleSegment.quote"
                              :defaultValue="true"
                          />
                        </b-form-group>
                      </div>
                      <div class="w-full">
                        <b-form-group
                            :label="$t(locale.notes)"
                            label-for="input-1"
                        >
                          <b-form-textarea
                              v-model="singleSegment.notes"
                              :placeholder="$t(locale.notes)"
                          />
                        </b-form-group>
                      </div>
                    </div>
                  </b-col>

                  <b-col cols="12" lg="4">
                    <div class="flex flex-col">
                      <!-- <p
                          class="h4 mb-2"
                          v-text="$t(locale.order)"
                      /> -->
                      <div class="w-full">
                        <b-form-group
                            :label="$t(locale.order)"
                            label-for="input-1"
                        >
                          <LittleTabs
                              :tabs="[
                                                            {
                                                                label: 'InvoiceCardCustomerEdit.print',
                                                                active: true,
                                                                value: 'print',
                                                            },
                                                            {
                                                                label: 'CustomersCardEdit.firstForm.email',
                                                                active: false,
                                                                value: 'email',
                                                            },
                                                        ]"
                              buttonSize="sm"
                              containerStyle="justify-content-start"
                              btnClass="tabWrapperSmall2"
                              v-model="singleSegment.order"
                              :defaultValue="true"
                          />
                        </b-form-group>
                      </div>
                    </div>
                  </b-col>
                </b-row>
              </b-container>
            </SecondaryCard>
          </b-col>
        </b-row>
      </div>

      <b-col cols="12">
        <b-row align-h="between" class="smallGap mt-1">
          <b-col cols="12" lg="3">

          </b-col>
          <b-col
              cols="12"
              lg="4"
              class="d-flex justify-content-between justify-lg-end"
          >
            <div class="">
              <b-button
                  block
                  variant="outline-danger"
                  @click="$router.go(-1)"
                  v-text="$t(locale.cancel)"
              />
            </div>
            <div class="">
              <b-button
                  @click="createCustomerSegment"
                  block
                  variant="primary"
                  v-text="processing ? $t('Message.loading') : $t('CustomersCardEdit.createSegment')"
              />
            </div>
          </b-col>
        </b-row>
      </b-col>
    </ValidationObserver>
    <Modal

        id="modal-access-denied"
        :hideFooter="true"
        :no-close-on-backdrop="true"
        :hide-header="true"
        :hide-back-drop="true"
    >
      <access-denied/>
    </Modal>
  </div>
</template>

<script>
import basic from '@/@core/components/editCustomer/basic.vue';
import invoice from '@/@core/components/editCustomer/invoice.vue';
import vSelect from 'vue-select';
import {ValidationProvider, ValidationObserver} from 'vee-validate';
import {getCountries} from './constants/countries'
import useAppConfig from '@core/app-config/useAppConfig';
import {CUSTOMER_DATA} from './constants/customer'
import {CUSTOMER_SEGMENT} from './constants/segment'
import accessDenied from "@core/components/permission/accessDenied";
import {mapGetters} from "vuex";

export default {
  name: 'CreateCustomerSegment',
  components: {
    basic,
    invoice,
    ValidationObserver,
    ValidationProvider,
    vSelect, accessDenied
  },
  data() {
    return {
      config: useAppConfig(),
      tabSwitch: 1,
      singleSegment: {...CUSTOMER_SEGMENT},
      sendingModalAction: false,
      processing: false,
      countries: [...getCountries()],
      currentComponent: 'basic',
      tabs: [
        {
          label: 'CustomersCardEdit.basic',
          component: 'basic',
          active: true,
        },
        {
          label: 'CustomersCardEdit.invoice',
          component: 'invoice',
          active: false,
        },
      ],
      locale: {
        back: "articleSectionCard.backOverview",
        tabs: 'CustomersCard',
        active: 'CustomersCardEdit.firstForm.active',
        notes: 'CustomersCardEdit.forthForm.notes',

        invoiceDetails: 'CustomersCardEdit.invoice',

        interestBilling: 'InvoiceCardCustomerEdit.interestBilling',
        invoicing: 'InvoiceCardCustomerEdit.invoicing',
        invoiceDiscount: 'InvoiceCardCustomerEdit.invoiceDiscount',
        invoiceCharge: 'InvoiceCardCustomerEdit.invoiceCharge',
        shippingCharge: 'InvoiceCardCustomerEdit.shippingCharge',
        priceVat: 'InvoiceCardCustomerEdit.priceVat',
        invoiceDistribution: 'InvoiceCardCustomerEdit.invoiceDistribution',
        print: 'InvoiceCardCustomerEdit.print',
        quote: 'InvoiceCardCustomerEdit.quote',
        invoiceText: 'InvoiceCardCustomerEdit.invoiceText',
        order: 'InvoiceCardCustomerEdit.order',
        invoice: 'InvoiceCardCustomerEdit.invoice',
        details: 'InvoiceCardCustomerEdit.details',
        delete: 'CustomersCardEdit.dltBtn',
        cancel: 'CustomersCardEdit.cancel',
        priceInvat: 'invoicesEditCard.priceIncVat',

        name: "CustomerSegment.name",
        description: "CustomerSegment.description",
        notes: "CustomerSegment.notes",
        recurring: "CustomerSegment.recurring",
        priceIncVat: "CustomerSegment.priceIncVat",
        interestBilling: "CustomerSegment.interestBilling",
        active: "CustomerSegment.active",
        invoiceDiscount: "CustomerSegment.invoiceDiscount",
        invoiceAdminFee: "CustomerSegment.invoiceAdminFee",
        invoiceFreight: "CustomerSegment.invoiceFreight",
        invoiceCharge: "CustomerSegment.invoiceCharge",
        typeOfVat: "CustomerSegment.typeOfVat",

        createCustomer: 'CustomersCardEdit.createCustomer',
      },
      waysOfDelivery: [],
      deliveryTerms: [],
      termsOfPayment: [],
      printTemplates: [],
      priceLists: [],
      currencies: [],
      costCenter: [],
      projects: [],
      accountResults: [],
      printTemplateInvoice: [],
      printTemplateOffer: [],
      printTemplateOrder: [],
      printTemplateCashInvoice: [],
    };
  },
  computed: {
    message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    features() {
      if (this.getLang == '') {
        return this.swedishLang ? 'features' : 'features';
      } else return this.getLang == 'sv' ? 'features' : 'features';
    },
    getLang() {
      return this.config.lang.value;
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  },
  methods: {
    switchTabs(tabIndex) {
      this.tabs.find(item => item.active == true).active = false;

      this.currentComponent = this.tabs[tabIndex].component;
      this.tabSwitch = this.tabs[tabIndex].component === 'invoice' ? 2 : 1

      this.tabs[tabIndex].active = true;
    },

    createCustomerSegment() {
      this.$refs.segmentForm.validate().then(res => {
        if (res) {
          this.processing = true
          const payload = this.singleSegment
          this.$useJwt.customSegment({URL: '/customers', method: 'post'}, payload)
              .then(res => {
                this.processing = false
                this.$router.push({
                  name: 'acountingCustomerSegmentEdit',
                  params: {id: res.data.uuid},
                  query: {redirect: true}
                })
                this.popupMsg(
                    this.$t('Message.Success'),
                    res.data[this.message],
                    'CheckIcon',
                    'success'
                );
              })
              .catch(err => {
                this.processing = false
                if (err.response.data && err.response.data && err.response.data.message) {
                  if (err.response.data.message.ErrorInformation) {
                    this.popupMsg(
                        this.$t('Message.Failed'),
                        err.response.data.message.ErrorInformation.message,
                        'AlertTriangleIcon',
                        'danger'
                    );
                  } else {
                    this.popupMsg(
                        this.$t('Message.Failed'),
                        err.response.data.message,
                        'AlertTriangleIcon',
                        'danger'
                    );
                  }
                } else {
                  this.popupMsg(
                      this.$t('Message.Failed'),
                      err.response.data[this.message],
                      'AlertTriangleIcon',
                      'danger'
                  );
                }
                if (err.response.status === 403) {
                  window.Bus.$emit('trigger-access-denied', {'data': err.response.data})
                }


              })
        }
      });
    },
    openAccessDeniedModal() {
      this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', true)
      this.$bvModal.show("modal-access-denied");

    },
    computeSelect(event, key) {
      this.singleSegment[key] = event.value
    },
    // getCustomerInformation (uuid) {
    //     this.$useJwt.getCustomersByid(uuid).then(res => {
    //         this.singleSegment = res.data.data
    //     });
    // },
  },

  mounted() {
    this.$store.commit('appConfig/UPDATE_ACCESS_DENIED', false)
  },
};
</script>

<style lang='scss'>
@import '@core/scss/vue/libs/vue-select.scss';

</style>
